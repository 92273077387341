const apiVersion = 'api/v1'

export const directories = [
	{ endpoint: `${ apiVersion }/example/`, state: 'example'}
]

export const app = {
	tariff: `${ apiVersion }/tariff/`,
	modules: `${ apiVersion }/module/`,
	appStatus: `${ apiVersion }/app/status/`,
}

export const student = {
	student: `${ apiVersion }/student/`,
	payment: `${ apiVersion }/payment/`,
	studentByPk: id => `${ apiVersion }/student/${id}/`,
	me: `${ apiVersion }/auth/me/`,
	auth: `${ apiVersion }/auth/login/`,
  authGoogleCode: `${ apiVersion }/auth/google/code`,
	password: id => `${ apiVersion }/student/password/${id}/`,
	paymentStatus: `${ apiVersion }/payment/status/`,
  registerMsg: `${ apiVersion }/student/msg/register/`,
}

export const course = {
  course: `${ apiVersion }/course/`,
  courseByPk: id => `${ apiVersion }/course/${id}/`,
  moduleByPk: id => `${ apiVersion }/module/student/${id}/`,
  // moduleByPk2: id => `${ apiVersion }/module/student/${id}/2`,
  moduleAdminByPk: id => `${ apiVersion }/module/${id}/`,
	current: `${ apiVersion }/course/current/`,
	coursesForStudent: `${ apiVersion }/course/student/`,
	courseByPkForStudent: id => `${ apiVersion }/course/student/${id}/`,
  feedback: id => `${ apiVersion }/feedback/${id ? id+'/' : ''}`,
}

export const lesson = {
  lesson: `${ apiVersion }/lesson/`,
  lessonByPk: id => `${ apiVersion }/lesson/${id}/`,
  homeworkByLesson: id => `${ apiVersion }/lesson/${id}/homework/`,
  homeworkByPk: homeworkId => `${ apiVersion }/lesson/homework/${homeworkId}/`,
  lessonLinkByPk: id => `${ apiVersion }/lesson/link/${id}/`,
  homeworkLinkByPk: id => `${ apiVersion }/lesson/homework/link/${id}/`,
}

export const media = {
  get: id => `${ apiVersion }/media/${id}/`,
  lessonMedia: id => `${ apiVersion }/media/lesson/${id}/`,
  homeworkMedia: id => `${ apiVersion }/media/homework/${id}/`,
  userMedia: id => `${ apiVersion }/media/homework/${id}/user/`,
  seen: id => `${ apiVersion }/media/homework/${id}/`,
  archive: id => `${ apiVersion }/media/homework/archive/${id}/`,
  product: id => `${ apiVersion }/media/product/${id}/`,
  lessonVideo: id => `${ apiVersion }/media/lesson/video/${id}/`,
}

export const shop = {
  product: `${ apiVersion }/product/`,
  studentProduct: `${ apiVersion }/product/student/`,
  productPublic: `${ apiVersion }/product/all/`,
  productById: id => `${ apiVersion }/product/${ id }/`,
  productArchiveById: id => `${ apiVersion }/product/student/archive/${ id }/`,
  category: `${ apiVersion }/categories/`,
  payments: `${ apiVersion }/payment/`,
  studentPayments: `${ apiVersion }/payment/student/`,
  paymentById: id => `${ apiVersion }/payment/${ id }/`,
}
