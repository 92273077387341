import Notify from 'simple-notify'
import i18n from '@/plugins/vue-i18n'

let result = null
let opt = {}

export default async (response, isViewNotification = false) => {
  let notificationText = ''
  switch (response.code) {
    case 200:
      opt = {
        type: 'success',
        title: 'notify.changed',
        text: 'notify.success'
      }
      isViewNotification && pushNotify(opt)
      return true
    case 201:
      notificationText = response.data.key ? `key.${ response.data.key }` : 'notify.success'
      opt = {
        type: 'success',
        title: 'notify.created',
        text: notificationText
      }
      isViewNotification && pushNotify(opt)
      return true
    case 204:
      // pushNotify()
      return true

    case 400:
    case 403:
    case 404:
      result = await response.data.json()
      opt = {
        type: 'error',
        title: 'notify.error',
        text: `notify.${result.error.split('!')[0]}`,
        code: response.code
      }
      isViewNotification && pushNotify(opt)
      return false
    case 500:
    case 504:
      result = await response.data.json()
      opt = {
        type: 'error',
        title: 'notify.serverError',
        text: 'notify.somethingWentWrong',
        code: response.code
      }
      isViewNotification && pushNotify(opt)
      return false

    case 401:
      opt = {
        type: 'warning',
        title: 'notify.authError',
        text: 'notify.sessionInvalid'
      }
      pushNotify(opt)
      return false

    default:
      return false
  }
}

const options = {
  speed: 300,
  customClass: null,
  customIcon: null,
  showIcon: true,
  showCloseButton: true,
  autoclose: true,
  autotimeout: 3000,
  gap: 20,
  distance: 20,
  type: 1,
  position: 'right top',
  effect: 'fade',
}

export const pushNotify = opt => {
  const text = opt.code ?
    `${ i18n.t(opt.text) }, ${ opt.code }` :
    i18n.t(opt.text)

  new Notify({
    status: opt.type,
    title: i18n.t(opt.title),
    text: text,
   ...options
  })
}
