export default [
  {
    path: '/shop',
    name: 'shop',
    meta: {
      lang: 'ua',
      public: true,
      picker: true
    },
    // redirect: '/shop/products/popular',
    redirect: '/shop/oops',
    // component: () => import('@/views/Site/Shop/Oops'),
    component: () => import('@/views/Site/Shop/Main'),
    children: [
      {
        path: 'products/:type',
        name: 'products-list',
        meta: {
          lang: 'ua',
          public: true,
          picker: true,
          breadcrumbs: [{
            text: 'home',
            path: '/shop/products/popular',
          }, {
            text: 'current',
            path: '',
          }]
        },
        redirect: '/shop/oops',
        component: () => import('@/views/Site/Shop/Main'),
      },
    ]
  }, {
    path: '/shop/products/:type/:id',
    name: 'products-card',
    meta: {
      lang: 'ua',
      public: true,
      picker: true
    },
    redirect: '/shop/oops',
    component: () => import('@/views/Site/Shop/Chanks/ProductCard'),
  }, {
    path: '/shop/cart',
    name: 'cart',
    meta: {
      lang: 'ua',
      public: true,
      picker: true
    },
    component: () => import('@/views/Site/Shop/Cart/Full.vue'),
  }, {
    path: '/shop/oops',
    name: 'shop-oops',
    meta: {
      lang: 'ua',
      public: true,
      picker: true
    },
    component: () => import('@/views/Site/ComingSoon.vue'),
  }
]
