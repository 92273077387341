import api from '@/plugins/api'
import { lesson as endpoint, media } from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'
import {pushNotify} from '@/configs/responseValidation'

const store = 'lesson'

export default {
  state: () => ({
    lessons: [],
    lesson: {},
    breadcrumbsData: {
      course: null,
      module: null,
    }
  }),
  mutations: {
    SET_LESSON_BREADCRUMBS (state, data) {
      state.breadcrumbsData = data
    }
  },
  actions: {
    async getLessons (context, reload = false) {
      if (!context.state.lessons.length || reload) {
        const response = await api.get(endpoint.lesson)

        context.commit('SET_DATA', { store, state: 'lessons', data: response.data })
        return responseValidation(response)
      }
      return true
    },
    async getLesson (context, id) {
      const response = await api.get(endpoint.lessonByPk(id))

      context.commit('SET_DATA', { store, state: 'lesson', data: response.data })
      return responseValidation(response)
    },
    async setLesson (context, body) {
      const response = await api.post(endpoint.lesson, { body: body })
      const isResult = await responseValidation(response, true)

      if (isResult) {
        await context.dispatch('getAdminModuleInfo', body.moduleId)
      }
      return { isResult, lessonId: response.data.id }
    },

    async updateLesson (context, data) {
      const response = await api.patch(endpoint.lessonByPk(data.id),
        { body: data.body })

      const isResult = await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getLesson', data.id)
        await context.dispatch('getAdminModuleInfo', data.body.moduleId)
      }
      return { isResult, lessonId: response.data.id }
    },

    async deleteLesson (context, data) {
      const response = await api.delete(endpoint.lessonByPk(data.lessonId))
      const isResult =  await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getAdminModuleInfo', data.moduleId)
      }
      return isResult
    },

    async deleteLessonLink (context, id) {
      const response = await api.delete(endpoint.lessonLinkByPk(id))
      return await responseValidation(response, true)
    },

    async setHomework (context, data) {
      const response = await api.post(endpoint.homeworkByLesson(data.body.lessonId),
        { body: data.body })

      const isResult = await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getLesson', data.body.lessonId)
      }
      return isResult
    },

    async updateHomework (context, data) {
      const response = await api.patch(endpoint.homeworkByPk(data.body.id),
        { body: data.body })

      const isResult = await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getAdminModuleInfo', data.module)

        // await context.dispatch('getLesson', data.id)
        // await context.dispatch('getCurrentCourse', true)
      }
      return isResult
    },

    async deleteHomework (context, data) {
      const response = await api.delete(endpoint.homeworkByPk(data.homeworkId))
      const isResult =  await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getLesson', data.lessonId)
      }
      // return isResult
    },

    async deleteHomeworkLink (context, id) {
      const response = await api.delete(endpoint.homeworkLinkByPk(id))
      return await responseValidation(response, true)
    },

    async setLessonMedia (context, data) {
      const result = await Promise.all(
        data.media.map(async m => {
          const formData = new FormData()
          formData.append('name', m.name)
          formData.append('file', m.file)
          const result = await api.post(media.lessonMedia(data.id), {
            body: formData
          })

          return await responseValidation(result, false)
        })
      )
      const isDone = result.every(i => i === true)
      if (!isDone) {
        pushNotify({
          type: 'error',
          title: 'Помилка',
          text: 'Не всі відео завантажено',
          code: 500
        })
      }
      return isDone


      // for (const m of data.media) {
      //   console.log(m)
      //   const formData = new FormData()
      //   // formData.append('name', 'file')
      //   formData.append('file', m.file)
      //   const result = await api.post(media.lessonMedia(data.id), {
      //     body: formData
      //   })
      //
      //   await responseValidation(result, false)
      // }
    },

    async setMedia (context, data) {
      const formData = new FormData()
      formData.append('name', 'file')
      for (const m of data.media) {
        formData.append('file', m)
      }
      const result = await api.post(media.homeworkMedia(data.id), {
        body: formData
      })

      await responseValidation(result, false)
    },

    async setStudentMedia (context, data) {
      const formData = new FormData()
      formData.append('name', 'file')
      formData.append('file', data.body.media)
      const response = await api.post(media.userMedia(data.id), {
        body: formData
      })

      console.log(response)
      return await responseValidation(response, true)

      // if (isResult) {
      //   await context.dispatch('getModuleInfo', data.moduleId)
      // }
      // return isResult
    }
  },

}
