export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      lang: 'ua',
      access: 'student',
    },
    redirect: '/dashboard/courses/2',
    component: () => import('@/views/Cabinet/Dashboard'),
    children: [
      {
        path: 'modules',
        name: 'modules',
        meta: {
          lang: 'ua',
          access: 'student',
        },
        redirect: '/dashboard/courses/2',
      },
      {
        path: 'courses',
        name: 'courses',
        meta: {
          lang: 'ua',
          access: 'student',
          breadcrumbs: [{
            text: 'home',
            path: '/dashboard',
          }, {
            text: 'current',
            path: '',
          }],
        },
        component: () => import('@/views/Cabinet/Course/Courses.vue'),
      }, {
        path: 'courses/:id',
        name: 'course-card',
        meta: {
          lang: 'ua',
          access: 'student',
          breadcrumbs: [{
            text: 'home',
            path: '/dashboard',
          }, {
            text: 'courses',
            path: '/dashboard/courses',
          }, {
            text: 'current',
            path: '',
          }],
        },
        component: () => import('@/views/Cabinet/Course/CourseCard.vue'),
      },  {
        path: 'modules/:id',
        name: 'module-card',
        meta: {
          lang: 'ua',
          access: 'student',
        },
        component: () => import('@/views/Cabinet/Course/Module/ModuleCard.vue'),
      },  {
        path: 'shop',
        name: 'student-shop-list',
        meta: {
          lang: 'ua',
          access: 'student',
        },
        component: () => import('@/views/Cabinet/Shop/Products.vue'),
      }
    ]
  },
]
