import en from '@/configs/translations/en'
import ua from '@/configs/translations/ua'
import ru from '@/configs/translations/ru'

let locale = 'ua'

export default {
  locale,
  fallbackLocale: 'ua',
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'ua',
    flag: 'ua',
    label: 'Ukrainian',
    messages: ua
  }, {
    code: 'ru',
    flag: 'ru',
    label: 'Russian',
    messages: ru
  }]
}
