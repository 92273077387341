const pluginOptions = {
  publicPages: ['/login', '/404'],
  tokenKey: 'bearer',
  loginPath: '/login',
  needResponse: false
}

const MAIN_URL = process.env.VUE_APP_API
if (!MAIN_URL) throw Error('VUE_APP_API not found, add api host in env as VUE_APP_API')

const HEADERS = {}
const OPTIONS = {}

export class API {
  constructor(options) {
    if (options) {
      if(options.publicPages) (pluginOptions.publicPages = options.publicPages)
      if(options.tokenKey) (pluginOptions.tokenKey = options.tokenKey)
      if(options.loginPath) (pluginOptions.loginPath = options.loginPath)
      if(options.needResponse) (pluginOptions.needResponse = options.needResponse)
    }

    HEADERS.json = {
      Accept : 'application/json',
      'Content-Type': 'application/json'
    }

    // HEADERS.blob = {
    //   Accept : 'application/json',
    //   'Content-Type': 'application/json'
    // }

    HEADERS.formData = {
      Accept : '*/*',
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }

    OPTIONS.responseType = 'json'
    OPTIONS.requestType = 'json'

    OPTIONS.headers = {
      'Access-Control-Request-Headers': '*'
    }
  }

  static async setFetchOption(url, clientOptions) {
    const fetchOptions = { ...OPTIONS, ...clientOptions}

    if (clientOptions.body && clientOptions.body.toString().includes('FormData')) {
      fetchOptions.requestType = 'formData'
    }

    //set option by type
    if (fetchOptions.requestType === 'json') {
      fetchOptions.headers = { ...fetchOptions.headers, ...HEADERS.json}
    }

    // //set option by type
    // if (fetchOptions.requestType === 'blob') {
    //   fetchOptions.headers = { ...fetchOptions.headers, ...HEADERS.formData}
    // }

    //set body
    if (clientOptions.body) {
      if (fetchOptions.requestType === 'formData') {
        fetchOptions.body = clientOptions.body
        fetchOptions.headers = { ...fetchOptions.headers, ...HEADERS.formData }
      } else if (fetchOptions.requestType === 'json') fetchOptions.body = JSON.stringify(clientOptions.body)
      else fetchOptions.body = clientOptions.body
    }

    // set Authorization headers
    if (!(clientOptions && clientOptions.headers &&
    clientOptions.headers.Authorization)) {
      document.cookie.split(';').forEach(i => {
        if (i.includes('JWT'))
        fetchOptions.headers.Authorization = i.split('=')[1]
      })
    }

    // set uri
    const uri = url.slice(0, 4) === 'http' ? url : `${ MAIN_URL }${ url }`

    return await this.fetchRequest(uri, fetchOptions)
  }

  static async fetchRequest (url, options) {
    const response = await fetch(url, options)
    const code = response.status

    if (code === 401) this.prototype.logout()
    if (code >= 200 && code <= 299 && code !== 204) {
      const data = await response[options.responseType]()
      const returnData = {
        code, data
      }
      if (options.needResponse) returnData.response = response
      return returnData
    } else return { code, data: response }
  }

  get(url, clientOptions) {
    const opt = {
      method: 'GET'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  post(url, clientOptions) {
    const opt = {
      method: 'POST'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  put(url, clientOptions) {
    const opt = {
      method: 'PUT'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  delete(url, clientOptions = null) {
    const opt = {
      method: 'DELETE'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  patch(url, clientOptions) {
    const opt = {
      method: 'PATCH'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  logout () {
    document.cookie = `JWT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    window.location = '/login'
  }
}



const api = new API()

export default api
