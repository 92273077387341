export default {
  state: () => ({
    title: {
      max: 2,
      current: 1
    },
    isActivePicker: true,
    isViewFirstTip: true,
    isViewSecondTip: false,
  }),
  mutations: {
    SET_TITLE (state) {
      state.isViewSecondTip = true
      state.isViewFirstTip = false
      const title = state.title
      console.log(title)
      if (title.current === title.max) title.current = 0
      else title.current += 1
    },
    SET_PICKER_ACTIVE (state, status) {
      state.isActivePicker = status
    }
  },
  actions: {

  },
  getters: {

  }
}
