import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/plugins/vue-i18n'
import store from '@/store'
import adminRoutes from '@/router/admin.routes'
import studentRoutes from '@/router/student.routes'
import shopRoutes from '@/router/shop.routes'
Vue.use(Router)

export const routes = [
  {
    path: '*',
    name: 'all',
    redirect: '/404'
  }, {
    path: '/',
    name: 'site',
    meta: {
      lang: 'ua',
      picker: true,
      public: true
    },
    component: () => import('@/views/Site/MainCourse.vue')
  }, {
    //   path: '/old',
    //   name: 'site-old',
    //   meta: {
    //     lang: 'ua',
    //     picker: true,
    //     public: true
    //   },
    //   component: () => import('@/views/Site/Main.vue')
    // }, {
    path: '/login',
    name: 'login',
    meta: {
      lang: 'ua',
      picker: false,
      public: true
    },
    component: () => import('@/views/Login')
  },
  ...studentRoutes,
  ...adminRoutes,
  ...shopRoutes,
  {
    path: '/me',
    name: 'me',
    meta: {
      lang: 'ua',
      picker: false,
      public: true
    },
    component: () => import('@/views/Me')
  }, {
    path: '/agreement',
    name: 'site-agreement',
    meta: {
      lang: 'ua',
      picker: false,
      public: true
    },
    component: () => import('@/views/Site/Agreement')
  }, {
    path: '/404',
    name: 'not-found',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/Main')
  }, {
    path: '/error',
    name: 'error',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Error.vue')
  }, {
    path: '/faq',
    name: 'faq',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/FAQ.vue')
  }, {
    path: '/student-works',
    name: 'student_works',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/StudentWorkFull.vue')
  }, {
    path: '/my-works',
    name: 'my_works',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/MyWorkFull.vue')
  }, {
    path: '/feedbacks',
    name: 'feedbacks',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/Feedback.vue')
  }, {
    path: '/post-login',
    name: 'post-login',
    meta: {
      lang: 'ua',
      public: false
    },
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const isProtectPages = !to.meta.public
  const isAutorized = document.cookie.includes('JWT')

  i18n.locale = 'ua'

  const appStatus = await store.dispatch('getAppStatus')

  if (to.name !== 'error' && appStatus.includes('all_error')) next('/error')
  else if (to.name === 'error' && !appStatus.includes('all_error')) next('/')

  const data = isAutorized ? await store.dispatch('getClientInfo') : null

  if (isProtectPages && isAutorized) {

    if (to.path === '/post-login') {
      if (data.role === 'admin') next('/admin')
      else next('/dashboard')
    }

    if (to.meta.access === 'admin' && data.role === 'admin') next()
    else if (data.role === 'admin' && to.path === '/dashboard/courses') next('/admin')
    else if (to.meta.access === 'admin' && data.role !== 'admin') next('/dashboard')
    else next()
  }
  else if (isProtectPages && !isAutorized) next('/login')
  else if (!isProtectPages) next()

})

export default router
