import api from '@/plugins/api'
import {media, shop as endpoint} from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'
import {pushNotify} from '@/configs/responseValidation'

const store = 'shop'

export default {
  state: () => ({
    products: [],
    product: {},
    categories: [],
    payments: [],
    payment: {},

    studentProducts: [],
    studentPayments: [],
  }),
  actions: {
    // STUDENT PRODUCTS
    async getStudentsProducts (context) {
      if (!context.state.studentProducts.length) {
        const response = await api.get(endpoint.studentProduct)
        context.commit('SET_DATA', { store, state: 'studentProducts', data: response.data })
        return responseValidation(response)
      }
      return true
    },
    async downloadProduct (context, product) {
      const response = await api.post(
        endpoint.productArchiveById(product.id),
        { responseType: 'blob' })
      console.log(response)
      const a = document.createElement('a');
      a.style.display = 'none';

      console.log(response.data)
      a.href = URL.createObjectURL(response.data);

      a.download = `${ product.name }.zip`;
      document.body.appendChild(a);
      a.click();

      return await responseValidation(response, false)
    },

    // PRODUCTS
    async getProducts (context, get = false) {
      if (!context.state.products.length || get) {
        const response = await api.get(endpoint.product)
        context.commit('SET_DATA', { store, state: 'products', data: response.data })
        return responseValidation(response)
      }
      return true
    },
    async getProductsPublic (context) {
      if (!context.state.products.length) {
        const response = await api.get(endpoint.productPublic)
        context.commit('SET_DATA', { store, state: 'products', data: response.data })
        return responseValidation(response)
      }
      return true
    },
    async getProductInfo (context, id) {
      console.log(id)
      const response = await api.get(endpoint.productById(id))
      context.commit('SET_DATA', { store, state: 'product', data: response.data })
      return responseValidation(response)
    },
    async setProduct (context, body) {
      const dataPictures = body.pictures
      const dataMainPictures = body.picturesMain
      const dataMedia = body.media

      delete body.media
      delete body.pictures
      delete body.picturesMain
      const response = await api.post(endpoint.product, { body })
      const isResult = await responseValidation(response, false)
      if (isResult) {
        await context.dispatch('setProductMedia', {
          id: response.data.id,
          type: 'mainPicture',
          media: [dataMainPictures]
        })
        await context.dispatch('setProductMedia', {
          id: response.data.id,
          type: 'picture',
          media: dataPictures
        })
        await context.dispatch('setProductMedia', {
          id: response.data.id,
          type: 'private',
          media: [dataMedia]
        })

        await context.dispatch('getProducts', true)
        pushNotify({
          type: 'success',
          title: 'notify.created',
          text: 'notify.productAdded',
          code: 200
        })
      }

      return isResult
    },
    async setProductMedia (context, data) {
      console.log(data)
      const formData = new FormData()
      formData.append('name', data.type)

      for (const m of data.media) {
        formData.append('file', m)
      }

      const result = await api.post(media.product(data.id, data.type), {
        body: formData
      })

      await responseValidation(result, false)
    },

    // CATEGORIES
    async getCategories (context, type = 'normal') {
      if (!context.state.categories.length) {
        const response = await api.get(endpoint.category)
        const data = Object.assign([], response.data)
        if (type === 'public') {
          // let t = Object.assign([], response.data)
          data.unshift({ id: 999999, name: 'Популярне', key: 'popular'})
        }
        console.log(data)
        context.commit('SET_DATA', { store, state: 'categories', data })
        return responseValidation(response)
      }
      return true
    },
    async setCategory (context, body) {
      const response = await api.post(endpoint.category, { body })
      const isResult = await responseValidation(response, true)

      isResult && context.commit('ADD_DATA', { store, state: 'categories', data: response.data })

      return { status: isResult, code: response.code }
    },

    // PAYMENTS
    async getPayments (context) {
      if (!context.state.payments.length) {
        const response = await api.get(endpoint.payments)
        const data = Object.assign([], response.data)

        context.commit('SET_DATA', { store, state: 'payments', data })
        return responseValidation(response)
      }
      return true
    },
    async getStudentPayments (context) {
      if (!context.state.studentPayments.length) {
        const response = await api.get(endpoint.studentPayments)
        const data = Object.assign([], response.data)

        context.commit('SET_DATA', { store, state: 'studentPayments', data })
        return responseValidation(response)
      }
      return true
    },
    async getPaymentInfo (context, id) {
      const response = await api.get(endpoint.paymentById(id))
      context.commit('SET_DATA', { store, state: 'payment', data: response.data })
      return responseValidation(response)
    },
  },
  getters: {

  }
}
