<template lang="pug">
  div
    div(style="width: 50px")
      div(id="block-picker-def"
        style="position: absolute; top: 25px; right: 25px"
        :class="isActivePicker ? 'picker-procreate_active' : 'picker-procreate_no-active'"
        ).picker-procreate.ml-6
      div(id="block-picker" style="position: absolute; top: 25px; right: 25px").pointer
        div(:class="isActivePicker ? 'picker-procreate_active' : 'picker-procreate_no-active'"
        ).picker-procreate.ml-6

    //div(v-if="isViewFirst").tip
    //  v-img(:src="Line" width="55" height="65").tip-img
    //  div.tip-block.py-2.font-13.white--text
    //    div
    //      span.opacity1.bold-600.mr-1 {{ $t('tips.tip1.text1') }}
    //      span.opacity1 {{ $t('tips.tip1.text2') }}
    //    div {{ $t('tips.tip1.text3') }}
    //    div.primary--text {{ $t('tips.tip1.text4') }}
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Line from '@/assets/icons/line_dash.svg'

export default {
  name: 'PickerRipple',
  props: {
    isSite: Boolean
  },
  data () {
    return {
      Line,
      isViewFirst: true,
      isMobile: false,
      browser: navigator.userAgent,
      delta_x: 0,
      delta_y: 0,
      ie: 0,
      op: 0,
      ff: 0,
      block: null,
      x: 0,
      y: 0,
      first_position_x: 0,
      first_position_y: 0,
    }
  },
  computed: {
    ...mapState({
      isActivePicker: state => state.picker.isActivePicker,
    })
  },
  mounted () {
    if (this.browser.indexOf("Opera") !== -1) this.op = 1;
    else {
      if (this.browser.indexOf("MSIE") !== -1) this.ie = 1;
      else {
        if (this.browser.indexOf("Firefox") !== -1) this.ff = 1;
      }
    }
    this.block = document.getElementById('block-picker')

    this.block.onmousedown = this.saveXY


    if (this.isMobile) {
      this.block.ontouchstart = this.saveXY
      this.block.ontouchend = this.clearXY
    } else {
      this.block.onmousedown = this.saveXY
      this.block.onmouseup = this.clearXY

      if (this.op || this.ff) {
        this.block.addEventListener("onmousedown", this.saveXY, false)
      }
    }
  },
  methods: {
    ...mapMutations(['SET_TITLE']),

    saveXY (obj_event) {
      console.log(this) //$root.$children[0].$children[0].$children[1].$el
      this.$root.$children[0].$children[0].$children[1].$el.scrollTop = 0
      /* coord cursor */
      if (obj_event) {
        this.x = obj_event.pageX
        this.y = obj_event.pageY
      }
      else {
        this.x = window.event.clientX
        this.y = window.event.clientY
        if (this.ie) {
          this.x -= 2
          this.y -= 2
        }
      }
      /* coord block */
      let x_block = this.block.offsetLeft;
      let y_block = this.block.offsetTop;

      /* Узнаём смещение */
      this.delta_x = x_block - this.x
      this.delta_y = y_block - this.y
      /* drag and listener move */
      if (this.isMobile) {
        document.ontouchmove = this.moveBlock
      } else {
        document.onmousemove = this.moveBlock
        if (this.op || this.ff) {
          document.addEventListener("onmousemove", this.moveBlock, false);
        }
      }

    },
    clearXY () {
      if (this.isMobile) {
        document.ontouchmove = null
      } else {
        document.onmousemove = null
      }

      this.block.style.top = '25px' //this.first_position_y + "px"
      this.block.style.right = '25px' //this.first_position_y + "px"
      this.block.style.left = null //this.first_position_x + "px"
      this.createRipple()
    },
    moveBlock (obj_event) {
      // this.$parent.$el.scrollTop = 0

      if (obj_event) {
        this.x = obj_event.pageX;
        this.y = obj_event.pageY;
      }
      else {
        this.x = window.event.clientX;
        this.y = window.event.clientY;
        if (this.ie) {
          this.x -= 2
          this.y -= 2
        }
      }
      /* new coord block */
      let new_x = this.delta_x + this.x
      let new_y = this.delta_y + this.y
      this.block.style.top = new_y + "px"
      this.block.style.left = new_x + "px"
    },

    createRipple () {
      if (this.isSite) {
        this.changeBg()
      } else this.changeTheme()


    },

    changeBg () {
      this.isViewFirst = false
      const bg = document.getElementById("change-bg")

      const circle = document.createElement("span")
      const diameter = Math.max(bg.clientWidth, bg.clientHeight)
      const radius = diameter / 2

      circle.style.width = circle.style.height = `${diameter}px`
      circle.style.left = `${this.x - (bg.offsetLeft + radius)}px`
      circle.style.top = `${this.y - (bg.offsetTop + radius)}px`
      circle.classList.add("ripple_bg")

      const ripple = bg.getElementsByClassName("ripple_bg")[0]

      if (ripple) {
        ripple.remove();
      }

      bg.appendChild(circle);

      setTimeout(() => this.SET_TITLE(), 10)
    },

    changeTheme () {
      const bg = document.getElementById("change-theme")

      const circle = document.createElement("span")
      const diameter = Math.max(bg.clientWidth, bg.clientHeight)
      const radius = diameter / 2

      circle.style.width = circle.style.height = `${diameter}px`
      circle.style.left = `${this.x - (bg.offsetLeft + radius)}px`
      circle.style.top = `${this.y - (bg.offsetTop + radius)}px`
      circle.classList.add("ripple_theme_white")

      const ripple = bg.getElementsByClassName("ripple_theme_white")[0]

      if (ripple) {
        ripple.remove();
      }

      bg.appendChild(circle);

      setTimeout(() => this.SET_TITLE(), 10)
    }
  }
}
</script>

<style scoped>

</style>
