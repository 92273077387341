export default {
  hello: '👋 Привет!',
  title: {
    text1: 'Procreate 2.0 Course',
    text2: 'Выучи программу от А до Я,',
    text3: 'получи реальную практику и фидбек.',
    text4: 'начни наконец рисовать без ограничений!',
  },
  aboutME: {
    text1: 'Меня зовут Никита Зинченко,',
    text2: 'я блогер-иллюстратор, и я рисую',
    text3: 'в Procreate уже больше 5 лет.',
    text4: '90% всех моих проектов я сделал',
    text5: 'в этой программе, а рисунки',
    text6: 'в ней принесли мне более',
    text7: '2 млн. подписчиков!',
  },
  aboutProcreate: {
    text1: {
      part1: 'Различные стили',
      part2: 'digital-рисунка',
    },
    text2: {
      part1: 'Работа с эффектами',
      part2: 'и обработка',
    },
    text3: {
      part1: 'Покадровая',
      part2: 'анимация',
    },
    text4: {
      part1: 'Студия по',
      part2: 'созданию кистей',
    },
    text5: {
      part1: 'Текстурирование и',
      part2: 'покраска 3D моделей',
    },
    text6: {
      part1: 'Скетчинг, заметки и',
      part2: 'работа с документами',
    },
  },
  procreate1: {
    text1: 'Procreate',
    text2: 'В первом потоке было',
    text3: '50 учеников',
    text4: '😱 и вот некоторые работы с курса',
    text5: '(2/3 нарисованы совсем новичками)',
  },
  whyMyCourse: {
    text1: 'Почему именно мой курс?',
    text2: {
      part1: 'Обучение от практикующего',
      part2: 'профессионального иллюстратора 😎',
    },
    text3: {
      part1: 'Все функции Procreate от А до Я',
      part2: '(идеально и для новичка, и для профи) ✅',
    },
    text4: {
      part1: 'Теория и практические задания',
      part2: 'для 100% результата 🚀',
    },
    text5: {
      part1: 'Разбор ваших работ',
      part2: 'после каждого д/з',
    },
    text6: 'Для кого этот курс? 🤔',
    text7: {
      part1: 'Начинающий',
      part2: 'digital художник',
    },
    text8: 'Я не знаю с чего начать. Я немного рисую, но не выходит крутой результат.',
    text9: 'Мне нужна практика, теоретические и технические знания, чтобы выйти на новый уровень!',
    text10: {
      part1: 'Хочу серьезно',
      part2: 'начать рисовать',
    },
    text11: 'У меня лежит iPad и даже скачан Procreate, но я не знаю, что в нем делать.',
    text12: 'Я хочу научиться рисовать и более серьезно этим заниматься, а после начать зарабатывать.',
    text13: {
      part1: 'Саморазвитие и',
      part2: '"для себя"',
    },
    text14: 'Мне иногда нужно что-то нарисовать по работе или для себя, но я не очень умею.',
    text15: 'Еще бывает так, что что-то в голове представить могу, а вот изобразить не выходит.('
  },
  program: {
    text1: 'Программа🔥',
    text2: '6 уроков',
    text3: 'Программа состоит из 6 занятий – это более 8-ми часов теории, где я расскажу про ВСЕ функции и секреты ' +
      'программы, каждую кнопку и настройку.',
    text4: 'Домашка',
    text5: 'После каждого занятия есть тематическое практическое задание. Оно прокачает как знание программы,' +
      ' так и умение рисовать.',
    text6: 'Разборы',
    text7: 'Я разбираю ваши домашние задания на отдельной онлайн встрече и делюсь советами и мыслями по улучшению ' +
      'ваших работ.',
    text8: 'Для пакета "Профи"',
    text9: 'я также',
    text10: 'проведу дополнительный мастермайнд',
    text11: 'с гайдом и советами, как зарабатывать начинающему иллюстратору в 2022 и как бесплатно продвигаться в сети.',
    text12: '+ Отдельная сессия вопрос-ответ по функциям программы.',
  },
  price: {
    title: 'Пакеты 🛍',
    text1: 'ВСЕ САМ 🤓',
    text2: 'ХУДОЖНИК 😎',
    text3: '6 видео уроков',
    text4: 'Домашние задания',
    text5: 'Нет разборов д/з',
    text6: 'Нет доступа к видео после курса',
    text7: 'Нет доп. сессии вопрос-ответ',
    text8: 'Нет бонусного мастермайнда',
    text9: '6 разборов д/з',
    text10: 'Безграничный доступ к материалам',
    text11: 'Доп. сессия вопрос-ответ',
    text12: 'Бонусный мастермайнд',
    text13: 'ПРОФИ 😍'
  },
  united: {
    text1: '⚠️ ️А пока я провожу позитивный курс и учу вас зарабатывать и создавать прекрасное, ' +
      'в Украине продолжается ужасная война, развязанная РФ.',
    text2: 'Поэтому 10% от заработанных денег с курса будет отправлено в фонд восстановления Украины UNITED 24',
    text3: '(отчет будет выслан всем ученикам)',
    text4: 'Слава Украине! 🇺🇦❤️'
  },
  faq: {
    title: {
      part1: 'Частые',
      part2: 'вопросы 😅',
    },
    question1: 'Когда начнется курс?',
    answer1: 'Курс начнется после набора группы, но не ранее 20 сентября и не позже 5 октября. ' +
      'Все зависит от количества желающих. Если курс не состоится по какой-либо причине (война в стране вообще-то), ' +
      'то все деньги будут возвращены на карты в полном объеме.',
    question2: 'На каком языке будут занятия?',
    answer2: 'Материалы видео будут на русском языке. В прошлом потоке было много учеников из Прибалтики, Германии, ' +
      'Швейцарии, Польши, Израиля и других стран. Они пока не выучили украинский, так что пока так',
    question3: 'Доступен ли курс для жителей РФ?',
    answer3: 'Нет. Импортозамещайте как-то.',
    question4: 'На какой платформе курс?',
    answer4: 'Видео будут выкладываться в интернет с ограниченным доступом и их можно будет просто посмотреть ' +
      'по ссылке без регистраций и СМС. А общение группы, напоминания о занятиях, сбор домашек и все-все-все ' +
      'остальное в телеграм канале и соответствующем чатике. ',
    question5: 'Как производится оплата?',
    answer5: 'На карту либо через PAYPAL. Для фанатов можно и в крипте (USDT, BUSD)',
    question6: 'А что, если я совсем не умею рисовать, вапше?\n',
    answer6: 'На первом потоке были такие ученики и они добились очень крутых результатов. Советую брать второй или ' +
      'третий пакет с разбором домашек и выполнять все на 100%. Я помогу и поддержу тебя и гарантирую 100% результат. ' +
      'Я через ZOOM учу 8-летнего мальчика из Швейцарии, с тобой мы точно сможем не хуже.)',
    question7: 'А вот если я уже умею рисовать и знаю Procreate?',
    answer7: 'Тогда все равно советую пройти курс, так как мы проходим все функции, а 99% пользователей используют ' +
      'далеко не все, что может эта программа и не раскрывают ее и свой потенциал. Поэтому ты точно узнаешь ' +
      'что-то новое, а все практические задания сделаны так, что они станут для тебя испытанием, которое прокачает ' +
      'твой уровень и добавит работы в твое портфолио. ',
    question8: 'Что нужно, чтобы пройти курс?',
    answer8: 'Нужен любой iPad и программа Procreate. Сразу ответ на следующий вопрос: если нет программы, ' +
      'если другой планшет и так далее - курс не для тебя. ',
    question9: 'Есть ли скидки?',
    answer9: 'Скидок нет. Я собирал эти знания не один год и специально поставил цены во много раз ниже реальных, ' +
      'чтобы у как можно большего количества людей была возможность прокачать себя или даже начать зарабатывать. ' +
      'Так что скидки уже учтены.)',
  },
  registration: 'Регистрация',
  join: 'Присоедениться',
  enterForm: 'Заполни форму',
  form: {
    name: 'Имя *',
    phone: 'Номер телефона *',
    password: 'Пароль *',
    email: 'Email *',
    telegram: 'Телеграм *',
    price: 'Пакет *',
    comment: 'Комментарий',
    title: 'Название *',
    task: 'Задание *',
    linkLesson: 'Ссылка на урок',
    linkHomework: 'Ссылка на разбор домашнего задания',
    linkHomeworkStream: 'Ссылка на разбор домашнего задания ONLINE',
    date: 'Дата *',
    time: 'Время *',
  },
  email: 'Email',
  validation: {
    required: 'Поле обязательное',
    phoneLength: 'Номер не действительный',
    example: 'пример',
  },
  password: 'Пароль',
  signIn: 'Войти',
  tariffs: {
    self: 'Все сам',
    artist: 'Художник',
    profi: 'Профи',
  },
  alert: {
    clientCreated: 'Аккаунт создан! Посмотри почту)',
    clientNotCreated: 'Извени, произошла ошибка( Обратись в чат',
    clientExist: 'Ты уже зарегистрирован',
    incorrectEmail: 'Не правильный email или пароль'
  },
  name: 'Имя',
  telegram: ' Телеграм',
  isPayed: ' Статус оплаты',
  isActive: ' Статус аккаунта',
  action: 'Действие',
  phone: 'Номер телефона',
  signAs: 'Вы вошли как',
  currentCourse: 'Текущий курс',
  lines: 'Потоки',
  lessons: 'Уроки',
  students: 'Студенты',
  student: 'Студент',
  back: 'Назад',
  studentWork: 'Работы студента',
  studentsCount: 'Студентов',
  dateStart: 'Дата старта',
  nextLesson: 'Следующий урок',
  currentLesson: 'Текущий урок',
  lastLesson: 'Пройденый урок',
  isActiveLesson: 'Урок активный',
  isCurrent: 'Текущий урок',
  isNext: 'Следующий урок',
  titleLesson: 'Название',
  course: 'Курс',
  lesson: 'Урок',
  task: 'Задание',
  lessonExample: 'Памятка',
  status: 'Статус',
  statuses: {
    last: 'Завершенный',
    current: 'Текущий',
    next: 'Следующий',
    planned: 'Запланированный',
    undefined: 'Не определен',
  },
  homeworks: 'Домашние задания',
  units: {
    count: 'шт'
  },
  plannedLesson: 'Запланований урок',
  doneHomeworks: 'Домашние работы',
  downloadHomeworks: 'Загрузить домашние работы',
  notify: {
    error: 'Ошибка',
    serverError: 'Ошибка сервера',
    somethingWentWrong: 'Что-то пошло не так',
    created: 'Создано',
    success: 'Все прошло успешно',
    authError: 'Ошибка авторизации',
    sessionInvalid: 'Сессия уже недоступна',
    changed: 'Изменено',
    'Password invalid': 'Пароль не верный',
    'Not found': 'Не найдено',
    'Content can not be empty': 'Не хватает данных',
    'User not found': 'Пользователь не найден',
    'Authentication failed': 'Ошибка авторизации',
    'Server error': 'Ошибка сервера',
    'Permissions denied': 'Нет доступа',
  },
  payed: 'Оплачено',
  notPayed: 'Не оплачено',
  active: 'Активно',
  notActive: 'Не активно',
  yes: 'Да',
  no: 'Нет',
  homework: 'Домашннее задание',
  homeworkOnline: 'Домашннее задание Online',
  tariff: 'Тариф',
  langInterface: 'Язык интерфейса',
  languages: {
    ua: 'Украинский',
    ru: 'Русский',
    en: 'Английский',
  },
  themeInterface: 'Тема интерфейса',
  themes: {
    light: 'Светлая тема',
    dark: 'Темная тема'
  },
  changePassword: 'Изменить пароль',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  editLesson: 'Изменение урока',
  createLesson: 'Создание урока',
  editHomeworkTask: 'Изменение домашнего задания',
  createHomeworkTask: 'Создание домашнего задания',
  downloadHomework: 'Загрузить домашнюю работу',
  onlyOneMedia: 'Ви можете загрузить домашнюю роботу только 1 раз, выбирайте самую лучшую',
  nameTitle:  'Название',
  addonsMedia:  'Добавленная медия',
  send: 'Отправить',
  addHomework: 'Добавить домашнее задание',
  addLesson: 'Добавить урок',
  closeLesson: 'Закончить',
  addStudent: 'Добавить студента',
  lessonsSoon: 'Занятия начнутся совсем скоро',
  lessonsSoon2: 'Остались считанные часы',
  isActiveHomework: 'Активность домашнего задания',
  newHomeworks: 'Новые домашние работы',
  homeworkChecked: 'Проверено, ожидайте ссылку на разбор',
  homeworkInChecking: 'Ожидает проверки',
  saveArchive: 'Сохранить архив',
  addFeedback: 'Оставить отзыв',
  textFeedback: 'Отзыв',
  isAgreeName: 'Разрешить показывать имя',
  isAgreeInstagram: 'Разрешить показывать ник instagram',
  instagramName: 'Ник instagram',
  feedbacks: 'Отзывы',

}
