<template lang="pug">
  v-app-bar(
    flat
    height="50"
    absolute
    elevate-on-scroll
  ).d-flex.align-center.justify-center.mt-4
    v-container.header-procreate.d-flex.align-center.justify-space-between.mt-5.px-6
      div.d-flex.align-center.justify-space-between.mr-4
        //Menu(v-if="isSite")
        img(:src="Logo" width="80px").ml-4

      router-link(to="/").white--text
        span(
          v-if="!isViewMiniMenu"
          :class="getActivePage('dashboard') && 'primary--text'"
        ).pointer.bold-500.ml-6 Головна
      router-link(to="/shop").white--text
        span(
          v-if="!isViewMiniMenu"
          :class="getActivePage('shop') && 'primary--text'"
        ).pointer.bold-500.ml-6 Маркет
      router-link(to="/student-works").white--text
        span(
          v-if="!isViewMiniMenu"
          :class="getActivePage('student-works') && 'primary--text'"
        ).pointer.bold-500.ml-6 Роботи учнів
      router-link(to="/my-works").white--text
        span(
          v-if="!isViewMiniMenu"
          :class="getActivePage('my-works') && 'primary--text'"
        ).pointer.bold-500.ml-6 Мої роботи
      router-link(to="/feedbacks").white--text
        span(
          v-if="!isViewMiniMenu"
          :class="getActivePage('feedbacks') && 'primary--text'"
        ).pointer.bold-500.ml-6 Відгуки
      router-link(to="/faq").white--text
        span(
          v-if="!isViewMiniMenu"
          :class="getActivePage('faq') && 'primary--text'"
        ).pointer.bold-500.ml-6 FAQ
      v-spacer



      div(v-if="!isViewMiniMenu").d-flex.align-center.justify-space-between
        router-link(v-if="isAutorized" to="/dashboard").mr-6
          v-icon(:color="getActiveIcon('dashboard') ? 'primary' : 'white'") mdi-home-outline

        router-link(v-if="!isAutorized" to="/login")
          v-icon mdi-account-outline
        router-link(v-else to="/me")
          v-icon(:color="getActiveIcon('me') ? 'primary' : 'white'") mdi-account-outline

        CartMini(v-if="!isAutorized")

        v-icon(v-if="isAutorized" @click="logout" ).ml-6 mdi-exit-to-app


      div(v-if="isViewMiniMenu").d-flex.align-center.justify-end
        v-menu(offset-y)
          template(#activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on").mr-6 mdi-menu
          v-list.menu
            v-list-item(v-for="(item, index) in menu" :key="index")
              router-link(:to="item.link").white--text
                v-list-item-title(:class="getActivePage(item.key) && 'primary--text'")
                  v-icon(:class="getActivePage(item.key) && 'primary--text'").mr-2 {{ item.icon }}
                  span {{ item.title }}

        router-link(v-if="isAutorized" to="/dashboard").mr-6
          v-icon(:color="getActiveIcon('dashboard') ? 'primary' : 'white'") mdi-home-outline

        router-link(v-if="!isAutorized" to="/login")
          v-icon mdi-account-outline
        router-link(v-else to="/me")
          v-icon(:color="getActiveIcon('me') ? 'primary' : 'white'") mdi-account-outline

        CartMini(v-if="!isAutorized").mr-6

        v-icon(v-if="isAutorized" @click="logout" ).ml-6 mdi-exit-to-app
        //PickerRipple(v-if="isViewPicker"  :isSite="isSite")


</template>

<script>
import { mapState } from 'vuex'
import Logo from '@/assets/img/logo.webp'
import CartMini from '@/views/Site/Shop/Cart/Mini.vue'

import PickerRipple from '@/components/PickerRipple'

export default {
  name: 'Header',
  components: {
    CartMini,
    PickerRipple,
  },
  data () {
    return {
      Logo,
      menu: [{
        link: '/',
        key: 'dashboard',
        icon: 'mdi-home',
        title: 'Головна'
      }, {
        link: '/shop',
        key: 'shop',
        icon: 'mdi-shopping',
        title: 'Маркет'
      }, {
        link: '/student-works',
        key: 'student-works',
        icon: 'mdi-brush',
        title: 'Роботи учнів'
      }, {
        link: '/my-works',
        key: 'my-works',
        icon: 'mdi-palette',
        title: 'Мої роботи'
      }, {
        link: '/feedbacks',
        key: 'feedbacks',
        icon: 'mdi-comment-text-multiple-outline',
        title: 'Відгуки'
      },  {
        link: '/faq',
        key: 'faq',
        icon: 'mdi-help-circle',
        title: 'FAQ'
      }, ]
    }
  },
  computed: {
    ...mapState({
      me: state => state.client.me,
      isMini: state => state.app.isMini,
      cartLength: state => state.cart.cart?.length
    }),
    lang () {
      return this.$route.meta.lang
    },
    isSite () {
      return this.$route.name ? this.$route?.name.includes('site') : true
    },
    isAutorized () {
      return document.cookie.includes('JWT')
    },
    isViewPicker () {
      return this.$route.meta?.picker
    },
    isViewMiniMenu () {
      return this.$vuetify.breakpoint.width <= 1194
    }
  },
  methods: {
    getActivePage(page) {
      switch (page) {
        case 'dashboard': return this.$route.path === '/'
        case 'shop':
        case 'student-works':
        case 'my-works':
        case 'feedbacks':
        case 'faq': return this.$route.path.includes(page) && !this.$route.path.includes('admin')
        default: return false
      }
    },
    getActiveIcon(page) {
      return this.$route.path.includes(`/${page}`)
      // switch (page) {
      //   case 'me': return this.$route.path.includes('/me')
      //   case 'dashboard': return this.$route.path.includes('/dashboard')
      //   default: return false
      // }
    },
    logout () {
      document.cookie = `JWT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      window.location = '/login'
    }
  }
}
</script>
