// import api from '@/plugins/api'
// import i18n from '@/plugins/vue-i18n'
// import { app as endpoint } from '@/configs/endpoints'
//
// const store = 'directory'


export default {
  state: () => ({
    statuses: [{
      id: 1,
      name: 'Поточний'
    }, {
      id: 2,
      name: 'Наступний'
    }, {
      id: 3,
      name: 'Запланований'
    }, {
      id: 4,
      name: 'Завершений'
    }, ],
    languages: [{
      id: 'ua',
    }, {
      id: 'ru',
    }],
    categories: [{
      id: 1,
      name: 'popular'
    }, {
      id: 2,
      name: 'texture'
    }, {
      id: 3,
      name: 'brash'
    }, {
      id: 4,
      name: 'diaries'
    }]
  }),

  actions: {

  },
  getters: {

  }
}
