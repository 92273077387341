import api from '@/plugins/api'
import { media as endpoint } from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'
import moment from 'moment'

export default {
  state: () => ({

  }),
  actions: {
    async getMedia (context, data) {
      const response = await api.get(endpoint.get(data.id),
        { responseType: 'blob' })

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = URL.createObjectURL(response.data);

      a.download = data.name
      document.body.appendChild(a)
      a.click();

      const isResult = await responseValidation(response, false)

      if (isResult) {
        await context.dispatch('getLesson', data.lessonId)
      }
      return isResult
    },
    async getMediaVideo (context, id) {
      const response = await api.get(endpoint.lessonVideo(id), { responseType: 'blob' })

      const isResult = await responseValidation(response, false)
      return isResult ? response.data : null
    },

    async setSeenMedia (context, data) {
      const response = await api.patch(endpoint.seen(data.id), { body: data.body })

      const isResult = await responseValidation(response, false)
      if (isResult) {
        await context.dispatch('getCurrentCourse', true)
      }

      return isResult
    },

    async downloadArchiveMedia (context, id) {
      // await api.post(endpoint.archive(id))
      const response = await api.post(endpoint.archive(id), { responseType: 'blob' })

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = URL.createObjectURL(response.data);

      a.download = `homeworks_${moment(new Date()).format('DD.MM.YYYY')}.zip`;
      document.body.appendChild(a);
      a.click();

      const isResult = await responseValidation(response, false)
      if (isResult) {
        await context.dispatch('getCurrentCourse', true)
      }

      return isResult
    }
  },

}
