import Vue from 'vue'
import Vuex from 'vuex'

import mutations from '@/store/mutations'

import App from './modules/app'
import Picker from './modules/picker'
import Client from './modules/client'
import Student from './modules/student'
import Course from './modules/course'
import Lesson from './modules/lesson'
import Directory from './modules/directory'
import Media from './modules/media'
import Feedback from './modules/feedback'
import Shop from './modules/shop'
import Cart from './modules/cart'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: App,
    picker: Picker,
    client: Client,
    student: Student,
    course: Course,
    lesson: Lesson,
    directory: Directory,
    media: Media,
    feedback: Feedback,
    shop: Shop,
    cart: Cart,
  },
  mutations
})

export default store
