import api from '@/plugins/api'
import { course as endpoint } from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'

const store = 'feedback'

export default {
  state: () => ({
    feedbacks: [],
    feedback: {},

  }),
  actions: {
    async getFeedbacks (context) {
      if (!context.state.feedbacks.length) {
        const response = await api.get(endpoint.feedback())
        context.commit('SET_DATA', { store, state: 'feedbacks', data: response.data })
        return responseValidation(response)
      }
      return true
    },
    async getFeedback (context, id) {
      const response = await api.get(endpoint.feedback(id))
      context.commit('SET_DATA', { store, state: 'feedback', data: response.data })
      return responseValidation(response)
    },
    // async updateStudent (context, data) {
    //   const response = await api.patch(endpoint.studentByPk(data.id),
    //     { body: data.body })
    //   const isResult = await responseValidation(response)
    //   if (isResult) {
    //     await context.dispatch('getStudent',data.id )
    //   }
    //   return isResult
    // },
    async setFeedback (context, data) {
      const response = await api.post(endpoint.feedback(),
        { body: data.body })
      const isResult = await responseValidation(response)
      if (isResult) {
        await context.dispatch('getCurrentCourseForStudent', true)
      }
      return isResult
    }
  },
  getters: {

  }
}
