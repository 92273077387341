<template lang="pug">
  v-main(id="scroll-target" style="max-height: 100vh"
    class="overflow-y-auto").pa-0.overflow-x-hidden
    div
      video(autoplay muted loop).bg-video
        source(:src="bdVideo" type="video/mp4")
      v-parallax(
        dark
        height="1100"
      ).paralax-title

        v-row(justify="center").align-content-center

          v-container
            v-col.col-12.text-center.mt-16
              div.title-big.mb-3
                div.font-60.bold-700 Тссс....🤫
                div.font-60.bold-700.primary--text Зовсім скоро!

        div.bg-gradient-bottom

</template>

<script>

import Logo from '@/assets/img/logo.webp'
import bdVideo from '@/assets/video/bg.mp4'

import { mapState } from 'vuex'

export default {
  name: 'ComingSoon',
  data () {
    return {
      Logo,
      bdVideo,
    }
  },
  computed: {
    ...mapState({
    }),
    // bg () {
    //   return this.bgSrc[this.currentBG]
    // }
  },
}
</script>

<style lang="sass" scoped>
.bg-video
  position: absolute
  max-height: 1100px
  height: 100%
  object-fit: fill !important

.title-img
  position: absolute
  right: 28vw
  top: 15vh

.tabs
  & div
    background: #1A1A22BF
    width: 450px
    min-width: 450px
    padding: 10px 20px
    height: 56px
    border-radius: 10px

</style>
