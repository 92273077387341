export default {
  hello: '👋 Привіт!',
  title: {
    text1: 'Znikson Art - Я закохаю тебе',
    text11: 'в малювання!',
    text2: 'Тут мої арт проєкти, роботи і відгуки 1000+ моїх учнів.',
    text3: 'Отримай доступ до навчальних матеріалів, які допоможуть тобі',
    text4: 'почати малювати і покращити твої навички вже сьогодні!',
    // text5: 'і почни свій шлях в ілюстрації за один курс!',
  },
  aboutME: {
    text1: 'Я Микита Зінченко, митець з Києва.',
    text2: 'Малювання для мене - це',
    text3: 'сила творити і втілювати мрії.',
    text4: 'Навчив малювати з нуля',
    text5: 'учнів і допоможу тобі почати,',
    text6: 'покращити свої навички,',
    text7: 'подолати творчі блоки',
    text8: 'і випрацювати звичку малювати',
  },
  aboutCourse: {
    text1: 'Про що курс?',
    text2: 'Курс Ultimate.ART - це',
    text3: 'МОДУЛЬНИЙ курс',
    text4: 'Простіше - декілька курсів в одному.',
    text5: 'Ти можеш пройти все, а',
    text6: 'можеш обрати тільки те, що тобі цікаво ',
  },
  procreate1: {
    text1: 'Роботи учнів',
    text2: 'Я можу довго розповідати про себе,',
    text3: 'але краще про мене скажуть',
    text4: 'арти моїх учнів з курсу:',
  },
  forWhom: {
    title: 'Для кого курс?',
    text1: {
      title: 'Новачок',
      text1: 'Я мрію навчитись малювати ',
      text2: 'і втілювати свої ідеї в життя. Я отримаю важливі базові знання і ',
      text3: 'зможу розвиватись в професії та заробляти!'
    },
    text2: {
      title: 'Ілюстратор',
      text1: 'Я вмію малювати, але ',
      text2: 'хочу поглибити знання, ',
      text3: 'отримати додаткові інструменти і ',
      text4: 'вийти на новий рівень, ',
      text5: 'щоб робити ще крутіше!'
    },
    text3: {
      title: 'Для себе',
      text1: 'Я не ілюстратор, але в моїй сфері чи ',
      text2: 'просто для себе я хочу створювати прекрасне. ',
      text3: 'Я нарешті зможу робити це професійно і самостійно!'
    }
  },
  whyMyCourse: {
    title: 'Чому саме цей курс?',
    text1: 'Хороше питання! За свою кар’єру ',
    text2: 'я бачив стільки поганих курсів і програм, ',
    text3: 'які просто ',
    text4: 'витрачають час та гроші ',
    text5: 'людей і залишають ні з чим.',
    text6: 'І я захотів це змінити!',
    block1: {
      title: 'Теорія і практика',
      text1: 'Засвоєння кожної функції і техніки на практиці. ',
      text2: 'Ти будеш не тільки знати, а й вміти!'
    },
    block2: {
      title: 'Комплексні знання',
      text1: 'Я не вчу малювати кота чи руку або викладати пост - ',
      text2: 'я навчаю самостійно думати і створювати.'
    },
    block3: {
      title: 'Навчає професіонал',
      text1: 'Автор курсу - практик.',
      text2: 'Понад 6 років роботи від незграбного початку до успішних проектів. Я знаю цей шлях.)'
    },
    block4: {
      title: 'Зворотній зв’зок',
      text1: 'Мені не байдуже, чи закінчиш ти курс',
      text2: 'і з чим підеш. Мені важливо передати знання і результат.'
    }
  },
  program: {
    text1: 'Програма 🔥',
    text2: '6 уроків',
    text3: 'Програма складається з 6 занять – це більше 8-ми часів теорії, де я разкажу про УСІ функції та секрети ' +
      'програми, кожну кнопку й налаштування.',
    text4: 'Домашка',
    text5: 'Після кожного занятя є тематичне практичне завдання. Воно прокачає як знання програми,' +
      ' так й вміння малювати.',
    text6: 'Розбори',
    text7: 'Я розбираю ваші домашні завдання на окремій онлайн зустрічі та ділюсь порадами й думками щодо покращення ' +
      'ваших робіт.',
    text8: 'Для пакету "Профі"',
    text9: 'Я також',
    text10: 'проведу додатковий мастермайнд',
    text11: 'з гайдом та порадами, как заробляти починаючому ілюстратору у 2022 й як безкоштовно просуватися в мережі.',
    text12: '+ Окрема сесія питання-відповідь по функціям програми.',
  },
  price: {
    title: 'Готові пакети',
    title2: 'Конструктор',
    text: 'Але ти можеш зібрати і',
    text2: 'свій власний курс!',
    // text3: 'використовуючи:',
    text3: 'Натисни на модулі нижче і подивись загальну суму в нашому конструкторі:',
  },
  united: {
    text1: '⚠️ ️А поки я проводжу позитивний курс та вчу вас заробляти й створювати прекрасне, ' +
      'в Україні продовжується жахлива війна, розв\'язана росією.',
    text2: 'Тому 10% від зароблених грошей з курсу буде відправлено у фонд відновлення України',
    text3: '(звіт буде висланий усім учням)',
    text4: 'Слава Україні! 🇺🇦❤️'
  },
  faq: {
    title: 'FAQ',
    question1: 'Коли почнеться курс?',
    answer1: 'Курс почнеться після набору групи, але не раніше 20 вересня та не пізніше 5 жовтня. ' +
      'Все залежить від кількості бажаючих. Якщо курс не відбудеться по який-небудь причині (війна в країні так-то), ' +
      'то усі гроші будуть повернені на карти в повному обсязі.',
    question2: 'Якою мовою будуть заняття?',
    answer2: 'Матервали відео будуть на російській мові. На минулому потоуі було багато учнів з Прибалтики, Німеччини, ' +
      'Швейцарії, Польші, Ізраілю та інших країн. Вони поки не вивчили українську, тому поки що так',
    question3: 'Чи доступний курс для жителів РФ?',
    answer3: 'Ні. Імпортозамінюйте якось.',
    question4: 'На якій платформі курс?',
    answer4: 'Відео будуть завантажуватись в мережу з обмеженим доступом та їх можна буде просто подивитись ' +
      'за посиланням без реєстрації та СМС. А спілкування групи, нагадування про заняття, сбір домашек та все-все-все ' +
      'інше в телеграм каналі й відповідному чатику. ',
    question5: 'Как відбувається оплата?',
    answer5: 'На карту або через PAYPAL. Для фанатів можна й в крипті (USDT, BUSD)',
    question6: 'А що, якщо я зовсім не вмію малювати, ну зовсім?\n',
    answer6: 'На першому потоці були такі учні й вони добилися дуже крутих результатів. Раджу брать другий чи ' +
      'третій пакет з розбором домашек та виконувати все на 100%. Я допоможу та підтримую тебе й нарнатую 100% результат. ' +
      'Я через ZOOM вчу 8-річного хлопчика з Швейцарії, тому з тобою ми точно зможемо не гірше.)',
    question7: 'А ось якщо я вже вмію малювати та знаю Procreate?',
    answer7: 'Тоді все рівно раджу пройти курс, так як ми проходимр усі функції, а 99% користувачів використовують ' +
      'далеко не все, що може ця програма й не розкривають її та свій потенціал. Тому ти точно дізнаєшся ' +
      'щось нове, а всі практичні завдания зроблені так, що вони стануть для тебя випробування, що прокачає ' +
      'твій рівень та додасть робіт в твоє портфоліо. ',
    question8: 'Що потрібно, щоб пройти курс?',
    answer8: 'Потрібен будь-який iPad та програма Procreate. Одразу відповідь на наступне питання: якщо немає програми, ' +
      'якщо інший планшет і тому подібне - курс не для тебе. ',
    question9: 'Чи є знижки?',
    answer9: 'Знижок нема. Я збирав ці знання не один рік та спеціально поставив ціни у багато разів нижче реальних, ' +
      'щоб якомога більша кількість людей мала можливість прокачати себе або навіть почати заробляти. ' +
      'Так що знижки вже враховані.)',
  },
  registration: 'Записатись',
  join: 'Приєднатися',
  enterForm: 'Заповни форму',
  form: {
    name: 'Імʼя *',
    phone: 'Номер телефону *',
    phoneNotReq: 'Номер телефону',
    password: 'Пароль *',
    email: 'Email *',
    emailNotReq: 'Email',
    telegram: 'Телеграм *',
    price: 'Пакет *',
    priceC: 'Ціна *',
    priceSimple: 'Ціна',
    comment: 'Коментар',
    title: 'Назва *',
    task: 'Завдання *',
    linkLesson: 'Посилання на урок',
    linkHomework: 'Посилання на розбір домашнього завдання',
    linkHomeworkStream: 'Посилання на розбір домашнього завдання ONLINE',
    date: 'Дата *',
    time: 'Час *',
    question: 'Напиши своє питання',
  },
  email: 'Email',
  validation: {
    required: 'Поле обовʼязкове',
    phoneLength: 'Номер не дійсний',
    example: 'приклад',
  },
  password: 'Пароль',
  signIn: 'Увійти',
  tariffs: {
    self: 'Все сам',
    artist: 'Художник',
    profi: 'Профі',
  },
  alert: {
    clientCreated: 'Акаунт створено! Переглянь пошту)',
    clientNotCreated: 'Вибач, сталася помилка( Звернися до чату',
    clientExist: 'Ти вже зареєструвався',
    incorrectEmail: 'Не вірний email або пароль'
  },
  name: 'Імʼя',
  telegram: ' Телеграм',
  isPayed: ' Статус оплати',
  isActive: ' Статус акаунту',
  action: 'Дія',
  phone: 'Номер телефону',
  signAs: 'Ви увійшли як',
  currentCourse: 'Поточний курс',
  lines: 'Потоки',
  lessons: 'Уроки',
  students: 'Студенти',
  student: 'Студент',
  back: 'Назад',
  studentWork: 'Роботи студента',
  studentsCount: 'Студентів',
  dateStart: 'Дата старту',
  nextLesson: 'Наступний урок',
  currentLesson: 'Поточний урок',
  lastLesson: 'Пройдений урок',
  isActiveLesson: 'Урок активний',
  isCurrent: 'Поточний урок',
  isNext: 'Наступний урок',
  titleLesson: 'Назва',
  course: 'Курс',
  lesson: 'Урок',
  task: 'Завдання',
  lessonExample: 'Памʼятка',
  status: 'Статус',
  statuses: {
    last: 'Завершений',
    current: 'Поточний',
    next: 'Наступний',
    planned: 'Заплановний',
    finished: 'Завершений',
    unlimited: 'Безлімітний доступ',
    undefined: 'Не визначено',
    done: 'Здано',
    notDone: 'Не здано',
    created: 'Створено',
    inProcess: 'В обробці',
    sumHold: 'Сума заблокована',
    success: 'Успішно',
    failure: 'Неуспішно',
    reversed: 'Оплата повернена після успіху',
    expired: 'Час дії вичерпано',
  },
  homeworks: 'Домашні завдання',
  units: {
    count: 'шт'
  },
  plannedLesson: 'Залпанований урок',
  doneHomeworks: 'Домашні роботи',
  downloadHomeworks: 'Завантажити домашні роботи',
  notify: {
    error: 'Помилка',
    serverError: 'Помилка серверу',
    somethingWentWrong: 'Шось пішло не так',
    created: 'Створено',
    success: 'Все пройшло успішно',
    authError: 'Помилка авторизації',
    sessionInvalid: 'Сесія вже недоступна',
    changed: 'Змінено',
    'Password invalid': 'Пароль не вірний',
    'Not found': 'Не знайдено',
    'Content can not be empty': 'Не вистачає даних',
    'User not found': 'Користувача не знадено',
    'Email or password incorrect': 'Невірні параметри авторизаціі',
    'Authentication failed': 'Помилка авторизації',
    'Server error': 'Помилка серверу',
    'Permissions denied': 'Немає доступу',
    'productAdded': 'Товар додано',
    'Not found!': 'Файл не знайдено!',
    'Homework exists': 'ДЗ до уроку вже є!',
    'Bad Request': 'Запит не вірний',
  },
  key: {
    'homework_created': 'ДЗ завантажено!',
  },
  payed: 'Сплачено',
  notPayed: 'Не сплачено',
  active: 'Активно',
  notActive: 'Не активно',
  yes: 'Так',
  no: 'Ні',
  homework: 'Домашнє завдання',
  homeworkOnline: 'Домашнє завдання Online',
  tariff: 'Тариф',
  langInterface: 'Мова інтерфейсу',
  languages: {
    ua: 'Українська',
    ru: 'Російська',
    en: 'Англійська',
  },
  themeInterface: 'Тема інтерфейсу',
  themes: {
    light: 'Світла тема',
    dark: 'Темна тема'
  },
  changePassword: 'Змінити пароль',
  oldPassword: 'Старий пароль',
  newPassword: 'Новий пароль',
  editLesson: 'Змінення уроку',
  createLesson: 'Створення уроку',
  editHomeworkTask: 'Змінення домашнього завдання',
  createHomeworkTask: 'Створення домашнього завдання',
  downloadHomework: 'Завантажити домашню роботу',
  onlyOneMedia: 'Ви можете завантажити лише 1 домашню роботу ,обирайте найкращу =)',
  nameTitle:  'Назва',
  addonsMedia:  'Приклади',
  send: 'Відправити',
  addHomework: 'Додати домашнє завдання',
  addLesson: 'Додати урок',
  closeLesson: 'Завершити',
  addStudent: 'Додати студента',
  lessonsSoon: 'Заняття почнуться зовсім скоро',
  lessonsSoon2: 'Залишились лічені години',
  isActiveHomework: 'Активність домашнього завдання',
  newHomeworks: 'Нові домашні роботи',
  homeworkChecked: 'Перевірено, очікуйте посилання на розбір',
  homeworkInChecking: 'Очікує перевірки',
  saveArchive: 'Зберегти архів',
  addFeedback: 'Залишити відгук',
  textFeedback: 'Відгук',
  isAgreeName: 'Дозволити показувати імʼя',
  isAgreeInstagram: 'Дозволити показувати нік instagram',
  instagramName: 'Нік instagram',
  feedbacks: 'Відгуки учнів',
  myCabinet: 'Мій кабінет',
  tips: {
    tip1: {
      text1: 'Free tip:',
      text2: 'Зафарбуй цю',
      text3: 'сторінку, перетягнувши',
      text4: 'кружечок на арт.)',
    },
    tip2: {
      text1: 'Вітаю! Ти вмієш зафарбовувати об’єкти в Procreate.',
      text2: 'Зміг це - зможеш пройти і курс.)',
      text3: 'Все, не відволікаю.))'
    }
  },
  detailed: 'Детальніше',
  roadmap: 'Roadmap',
  toProject: 'проекту:',
  format: {
    title: 'Який формат?',
    text1: 'Я поставив собі за мету зробити курс ',
    text1_2: 'максимально доступним і продуктивним,',
    text2: 'тому він включає:',
    text3: 'Десятки годин ',
    text4: 'відеоуроків',
    text5: 'Практичні',
    text6: 'домашні завдання',
    text7: 'Майстермайнди',
    text8: '(online + запис)',
    text9: 'Розбори д/з',
    text10: 'Канал для спілкування',
    text11: 'зі мною і групою',
    text12: 'Додаткові матеріали',
    text13: '(тексти, зображення, гайди, електронні ресурси)',
  },
  sum: 'Загальна сума',
  yourModules: 'Тут будуть відображатися ваші модулі:',
  notificationRegistration: 'При купівлі курсу вам буде автоматично створено обліковий запис в системі',
  agreement: {
    text1: 'Я даю згоду на',
    text2: 'обробку персональних даних',
  },
  myHomework: 'Завантажена домашня робота',
  modules: 'Модулі',
  module: 'Модуль',
  courses: 'Курси',
  invoiceId: 'Транзакція',
  payment: {
    created: 'Очікується оплата',
    processing: 'Платіж обробляється',
    hold: 'Сума заблокована',
    success: 'Успішна оплата',
    failure: 'Неуспішна оплата',
    reversed: 'Повернена після успіху',
    expired: 'Час дії вичерпано'
  },
  createdAt: 'Дата створення',
  block: 'Заблокувати',
  unblock: 'Разблокувати',
  categories: {
    popular: 'Популярне',
    texture: 'Текстури',
    brash: 'Пензлі',
    diaries: 'Щоденники',
  },
  shop: 'Маркет',
  category: 'Категорія',
  categoriesLabel: 'Категорії',
  products: 'Товари',
  addProduct: 'Додати товар',
  addCategory: 'Додати катеорію',
  productOnSale: 'Товар на знижці',
  productNotOnSale: 'Товар не на знижці',
  productDigital: 'Digital товар',
  productNotDigital: 'Фізичний товар',
  availability: 'Доступно',
  codeProduct: 'Код товару',
  nameProduct: 'Назва',
  type: 'Тип',
  sale: 'Знижка',
  description: 'Опис',
  payments: 'Оплати',
  buyer: 'Покупець',
  yourProducts: 'Тут будуть відображатися ваші товари:',
  yourPayments: 'Тут будуть відображатися ваші оплати:',
  amount: 'Сума',
  goShopping: 'Перейти в маркет',
  version: 'Версія',
}
