import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    dark: true,
    default: 'dark',
    themes: {
      dark: {
        background: '#05090c',
        surface: '#111b27',
        primary: '#FF6827',
        // primary: '#F047FF',
        // primary: '#ff28a4',
        secondary: '#829099',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },

      // light theme colors
      light: {
        background: '#F8F8F8',
        surface: '#f2f5f8',
        primary: '#FF6827',
        // primary: '#ff28a4',
        secondary: '#a0b9c8',
        accent: '#048ba8',
        error: '#ef476f',
        info: '#2196F3',
        success: '#06d6a0',
        warning: '#ffd166'
      }
    },
  },
});

