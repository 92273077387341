const current = 1
const next = 2
const planned = 3
const last = 4

const lessonStatus = id => {
  switch (id) {
    case last: return { color: 'error--text', text: 'statuses.last' }
    case current: return { color: 'success--text', text: 'statuses.current' }
    case next: return { color: 'info--text', text: 'statuses.next' }
    case planned: return { color: 'warning--text', text: 'statuses.planed' }
  }
}
const paymentStatus = id => {
  switch (id) {
    case 'created': return { color: 'info--text', text: 'statuses.created' }
    case 'processing': return { color: 'info--text', text: 'statuses.inProcess' }
    case 'hold': return { color: 'info--text', text: 'statuses.sumHold' }
    case 'success': return { color: 'success--text', text: 'statuses.success' }
    case 'failure': return { color: 'error--text', text: 'statuses.failure' }
    case 'reversed': return { color: 'warning--text', text: 'statuses.reversed' }
    case 'expired': return { color: 'warning--text', text: 'statuses.expired' }
  }
}

// created - рахунок створено успішно, очікується оплата
// processing - платіж обробляється
// hold - сума заблокована
// success - успішна оплата
// failure - неуспішна оплата
// reversed - оплата повернена після успіху
// expired - час дії вичерпано

export default {
  last,
  current,
  next,
  planned,
  lessonStatus,
  paymentStatus
}
